import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import FAQManagementTable from "./faq_management_detail/faqManagementTable";
import { getProjectFaqAsync } from "../../projectManagement/redux/";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export const FaqManagement = () => {
  const { refreshFaqList } = useSelector(
    (state) => state.projectManagement,
    shallowEqual
  );
  const dispatch = useDispatch();
  const params = queryString.parse(window.location.search).projectId;
  const history = useHistory();
  useEffect(() => {
    if (params !== null && params !== undefined) {
      dispatch(getProjectFaqAsync(params));
    } else {
      return history.push("/project-management");
    }
  }, [refreshFaqList]);

  return (
    <>
      <Card>
        <CardHeader
          title={
            <div className="form-group m-0 d-flex align-items-center">
              <button
                className="btn btn-link px-0"
                onClick={() => history.push("/project-management")}
              >
                <SVG
                  className="h-50 align-self-center"
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-left.svg"
                  )}
                />
                Back
              </button>
              <span className="pl-2">FAQ Management</span>
            </div>
          }
        >
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              ></form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <FAQManagementTable />
      </Card>
    </>
  );
};
