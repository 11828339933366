import React, { useState, useEffect } from "react";
import { CardSkill } from "./CardSkill";
import { Card, CardBody } from "./../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllMasterDataAsync } from "../redux";
import { CircularProgress } from "@material-ui/core";
import { AddCardSkill } from "./addCard";

export const MasterManagement = () => {
  const dispatch = useDispatch();
  const { refreshMasterList, masterData, isLoading } = useSelector(
    (state) => state.masterManagement,
    shallowEqual
  );

  useEffect(() => {
    if (refreshMasterList) {
      dispatch(getAllMasterDataAsync());
    }
  }, [refreshMasterList]);
  const [AddModal, setAddModal] = useState(false);
  const AddClose = () => setAddModal(false);
  const AddShow = () => setAddModal(true);
  return (
    <>
      <div className="row">
        {isLoading && (
          <div className="basic-verification-loader text-center">
            <CircularProgress />
          </div>
        )}
        {masterData.length > 0
          ? masterData.map((data) => (
              <div key={data._id} className="col-lg-6 col-xxl-4">
                <CardSkill
                  placeholder="Enter value"
                  data={data}
                  isLoading={isLoading}
                />
              </div>
            ))
          : null}
        {/* {!isLoading && (
          <div className="col-lg-6 col-xxl-4">
            <Card className="card-stretch">
              <CardBody className=" d-flex align-items-center justify-content-center">
                <button
                  className="btn-link btn"
                  type="button"
                  onClick={AddShow}
                >
                  <img
                    className="w-150px"
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                  />
                </button>
              </CardBody>
            </Card>
          </div>
        )} */}
      </div>
      {/* <Modal
        show={AddModal}
        onHide={AddClose}
        centered
        size="md"
        className="def_modal show_close_button"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCardSkill AddClose={AddClose} />
        </Modal.Body>
      </Modal> */}
    </>
  );
};
