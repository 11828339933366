export const MasterManagementMap = {
  GET_ALL_MASTER_DATA_START: "GET_ALL_MASTER_DATA_START",
  GET_ALL_MASTER_DATA_SUCCESS: "GET_ALL_MASTER_DATA_SUCCESS",
  GET_ALL_MASTER_DATA_ERROR: "GET_ALL_MASTER_DATA_ERROR",
  ADD_MASTER_VALUE_START: "ADD_MASTER_VALUE_START",
  ADD_MASTER_VALUE_SUCCESS: "ADD_MASTER_VALUE_SUCCESS",
  ADD_MASTER_VALUE_ERROR: "ADD_MASTER_VALUE_ERROR",
  CREATE_MASTER_DATA_START: "CREATE_MASTER_DATA_START",
  CREATE_MASTER_DATA_SUCCESS: "CREATE_MASTER_DATA_SUCCESS",
  CREATE_MASTER_DATA_ERROR: "CREATE_MASTER_DATA_ERROR",
  REFRESH_MASTER_LIST: "REFRESH_MASTER_LIST",
  UPDATE_MASTER_DATA_START: "UPDATE_MASTER_DATA_START",
  UPDATE_MASTER_DATA_SUCCESS: "UPDATE_MASTER_DATA_SUCCESS",
  UPDATE_MASTER_DATA_ERROR: "UPDATE_MASTER_DATA_ERROR",
  DELETE_MASTER_DATA_START: "DELETE_MASTER_DATA_START",
  DELETE_MASTER_DATA_SUCCESS: "DELETE_MASTER_DATA_SUCCESS",
  DELETE_MASTER_DATA_ERROR: "DELETE_MASTER_DATA_ERROR",
};

export const MasterManagementActions = {
  getAllMasterDataStart: (data) => ({
    type: MasterManagementMap.GET_ALL_MASTER_DATA_START,
    payload: data,
  }),
  getAllMasterDataSuccess: (data) => ({
    type: MasterManagementMap.GET_ALL_MASTER_DATA_SUCCESS,
    payload: data,
  }),
  getAllMasterDataError: (errors) => ({
    type: MasterManagementMap.GET_ALL_MASTER_DATA_ERROR,
    payload: { errors },
  }),
  addMasterValuesStart: (data) => ({
    type: MasterManagementMap.ADD_MASTER_VALUE_START,
    payload: data,
  }),
  addMasterValuesSuccess: (data) => ({
    type: MasterManagementMap.ADD_MASTER_VALUE_SUCCESS,
    payload: data,
  }),
  addMasterValuesError: (data) => ({
    type: MasterManagementMap.ADD_MASTER_VALUE_ERROR,
    payload: data,
  }),
  deleteMasterDataStart: (data) => ({
    type: MasterManagementMap.DELETE_MASTER_DATA_START,
    payload: data,
  }),
  deleteMasterDataSuccess: (data) => ({
    type: MasterManagementMap.DELETE_MASTER_DATA_SUCCESS,
    payload: data,
  }),
  deleteMasterDataError: (errors) => ({
    type: MasterManagementMap.DELETE_MASTER_DATA_ERROR,
    payload: { errors },
  }),
  createMasterDataStart: (data) => ({
    type: MasterManagementMap.CREATE_MASTER_DATA_START,
    payload: data,
  }),
  createMasterDataSuccess: (data) => ({
    type: MasterManagementMap.CREATE_MASTER_DATA_SUCCESS,
    payload: data,
  }),
  createMasterDataError: (errors) => ({
    type: MasterManagementMap.CREATE_MASTER_DATA_ERROR,
    payload: { errors },
  }),
  updateMasterDataStart: (data) => ({
    type: MasterManagementMap.UPDATE_MASTER_DATA_START,
    payload: data,
  }),
  updateMasterDataSuccess: (data) => ({
    type: MasterManagementMap.UPDATE_MASTER_DATA_SUCCESS,
    payload: data,
  }),
  updateMasterDataError: (errors) => ({
    type: MasterManagementMap.UPDATE_MASTER_DATA_ERROR,
    payload: { errors },
  }),
  refreshEmailList: () => ({ type: MasterManagementMap.REFRESH_MASTER_LIST }),
};
