import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "./../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { addMasterValuesAsync, deleteMasterValuesAsync } from "../redux";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

export const CardSkill = (props) => {
  const dispatch = useDispatch();

  const [DeleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => {
    setDeleteModal(false);
    setSelectedValue({});
  };
  const deleteShow = () => setDeleteModal(true);

  const [AlreadyUsedModal, setAlreadyUsedModal] = useState(false);
  const closeModal = () => {
    setAlreadyUsedModal(false);
    setSelectedValue({});
  };
  const showModal = () => setAlreadyUsedModal(true);
  const [selectedValue, setSelectedValue] = useState({});

  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const onDeleteClick = (item) => {
    deleteShow();
    setSelectedValue(item);
  };
  useEffect(() => {
    const value =
      props.data &&
      props.data.values.map((value) => {
        return value;
      });
    setItems(...items, value);
  }, []);
  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      var value1 = value.trim();

      if (value1) {
        const data = {
          values: [
            {
              name: value1,
            },
          ],
        };
        const id = {
          id: props.data._id,
        };

        dispatch(addMasterValuesAsync(data, id));
        setValue("");
      }
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = () => {
    if (selectedValue && !selectedValue.isUsed) {
      dispatch(deleteMasterValuesAsync(props.data._id, selectedValue._id));
      setSelectedValue({});
      return deleteClose();
    } else {
      deleteClose();
      showModal();
    }

    // setItems(items.filter((i) => i !== item));
  };

  return (
    <>
      <Card className="card-stretch">
        <CardHeader title={props.data ? props.data.type : ""}></CardHeader>
        <CardBody>
          <input
            className={"form-control w-100" + (error && " has-error")}
            value={value}
            placeholder={props.placeholder}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          {error && <p className="fv-help-block text-danger">{error}</p>}
          {items.length > 0 && (
            <div className="d-flex align-items-center flex-wrap pt-3">
              {items.map((item, index) => (
                <div className="re_tag_item" key={item._id}>
                  {item.name}
                  <button
                    type="button"
                    className="button"
                    onClick={() => onDeleteClick(item)}
                  >
                    <img
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Close.svg"
                      )}
                    />
                  </button>
                </div>
              ))}
            </div>
          )}
          {props.AddBtn && (
            <div className="text-center pt-5">
              <button className="btn-blue btn-sm" type="button">
                Add
              </button>
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        show={DeleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want to delete?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={deleteClose}
                >
                  Close
                </button>
              </div>
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={handleDelete}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Already in use Modal */}
      <Modal
        show={AlreadyUsedModal}
        onHide={closeModal}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              You can not delete this.This value is already being used.
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <div className="form-group mr-5 mb-1 mt-2">
                <button
                  className="btn_new btn-sm"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
