import { MasterManagementMap } from "./masterManagementAction";

const initialState = {
  isLoading: false,
  masterData: [],
  refreshMasterList: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MasterManagementMap.GET_ALL_MASTER_DATA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterManagementMap.GET_ALL_MASTER_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        masterData: action.payload,
        refreshMasterList: false,
      };
    }
    case MasterManagementMap.GET_ALL_MASTER_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshMasterList: false,
      };
    }
    case MasterManagementMap.ADD_MASTER_VALUE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterManagementMap.ADD_MASTER_VALUE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        masterData: action.payload,
        refreshMasterList: true,
      };
    }
    case MasterManagementMap.ADD_MASTER_VALUE_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshMasterList: false,
      };
    }

    case MasterManagementMap.CREATE_MASTER_DATA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterManagementMap.CREATE_MASTER_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        masterData: action.payload,
        refreshMasterList: true,
      };
    }
    case MasterManagementMap.CREATE_MASTER_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshMasterList: false,
      };
    }
    // case EmailManagementMap.ADD_EMAIL_START: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }
    // case EmailManagementMap.ADD_EMAIL_SUCCESS: {
    //   return {
    //     ...state,
    //     refreshEmailList: true,
    //   };
    // }
    // case EmailManagementMap.ADD_EMAIL_ERROR: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     refreshEmailList: false,
    //   };
    // }
    // case EmailManagementMap.UPDATE_EMAIL_START: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }
    // case EmailManagementMap.UPDATE_EMAIL_SUCCESS: {
    //   return {
    //     ...state,
    //     refreshEmailList: true,
    //   };
    // }
    // case EmailManagementMap.UPDATE_EMAIL_ERROR: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     refreshEmailList: false,
    //   };
    // }
    // case EmailManagementMap.GET_ALL_TEMPLATES_START: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }
    // case EmailManagementMap.GET_ALL_TEMPLATES_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     templates: action.payload,
    //     refreshEmailList: true,
    //   };
    // }
    // case EmailManagementMap.GET_ALL_TEMPLATES_ERROR: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     templates: [],
    //     refreshEmailList: false,
    //   };
    // }
    // case EmailManagementMap.SELECT_EMAIL_DETAILS: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     selectedEmail: action.payload,
    //   };
    // }
    // case EmailManagementMap.SEARCH_TEXT_CHANGE: {
    //   return {
    //     ...state,
    //     searchText: action.payload,
    //   };
    // }
    // case EmailManagementMap.SET_EMAIL_BATCH_NUMBER: {
    //   return {
    //     ...state,
    //     skip: action.payload,
    //     refreshEmailList: true,
    //   };
    // }
    // case EmailManagementMap.REFRESH_EMAIL_LIST: {
    //   return {
    //     ...state,
    //     refreshEmailList: true,
    //   };
    // }
    // case EmailManagementMap.SELECT_EMAIL_DETAILS: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     selectedEmail: action.payload,
    //   };
    // }
    case MasterManagementMap.DELETE_MASTER_DATA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case MasterManagementMap.DELETE_MASTER_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        masterData: action.payload,
        refreshMasterList: true,
      };
    }
    case MasterManagementMap.DELETE_MASTER_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshMasterList: false,
      };
    }
    default:
      return { ...state };
  }
};
