import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addFaqAsync } from "../redux/tickets&SupportApi";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

const CreateFaqManagement = ({
  createFaqModalClose,
  selectedTicket,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { projectDetails } = selectedTicket;

  const addFaqSchema = () =>
    Yup.object().shape({
      question: Yup.string()
        .trim()
        .min(3, "Minimum 3 characters")
        .required("Question is required"),
      answer: Yup.string()
        .trim()
        .min(3, "Minimum 3 characters")
        .required("Answer is required"),
    });

  const formik = useFormik({
    initialValues: {
      question: selectedTicket.subject || "",
      answer: selectedTicket.details || "",
    },
    enableReinitialize: true,
    validationSchema: addFaqSchema,
    onSubmit: async (values, { resetForm }) => {
      dispatch(addFaqAsync(values, projectDetails && projectDetails._id));
      resetForm();
      createFaqModalClose();
    },
  });

  return (
    <>
      <form className="form def_form frmwtpddng" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-label">Question</label>
              <input
                placeholder="subject"
                type="text"
                className={`form-control`}
                name="question"
                {...formik.getFieldProps("question")}
              />
              {formik.touched.question && formik.errors.question ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.question}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label className="form-label">Answer</label>
              <textarea
                placeholder="details"
                type="text"
                className={`form-control`}
                name="answer"
                {...formik.getFieldProps("answer")}
              />
              {formik.touched.answer && formik.errors.answer ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.answer}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
          <div className="form-group mr-5 mb-1 mt-2">
            <button
              type="submit"
              className="btn btn-blue spinnerBtn"
              disabled={isLoading}
            >
              Save
              {isLoading && (
                <div className="ml-3 basic-verification-loader text-center">
                  <CircularProgress />
                </div>
              )}
            </button>
          </div>
          <div className="mr-5 mb-1 mt-2">
            <button
              type="button"
              className="btn btn-outline-blue"
              onClick={createFaqModalClose}
            >
              Close
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateFaqManagement;
