export const ProjectManagementMap = {
  GET_ALL_PROJECT_DETAIL_START: "GET_ALL_PROJECT_DETAIL_START",
  GET_ALL_PROJECT_DETAIL_SUCCESS: "GET_ALL_PROJECT_DETAIL_SUCCESS",
  GET_ALL_PROJECT_DETAIL_ERROR: "GET_ALL_PROJECT_DETAIL_ERROR",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  SEARCH_STATUS_CHANGE: "SEARCH_STATUS_CHANGE",
  REFRESH_PROJECT_DETAILS_LIST: "REFRESH_PROJECT_DETAILS_LIST",
  SET_PROJECT_DETAIL_BATCH_NUMBER: "SET_PROJECT_DETAIL_BATCH_NUMBER",
  UPDATE_PROJECT_DETAIL_START: "UPDATE_PROJECT_DETAIL_START",
  UPDATE_PROJECT_DETAIL_SUCCESS: "UPDATE_PROJECT_DETAIL_SUCCESS",
  UPDATE_PROJECT_DETAIL_ERROR: "UPDATE_PROJECT_DETAIL_ERROR",
  GET_PROJECT_START: "GET_PROJECT_START",
  GET_PROJECT_SUCCESS: "GET_PROJECT_SUCCESS",
  GET_PROJECT_ERROR: "GET_PROJECT_ERROR",
  GET_PROJECT_FAQ_START: "GET_PROJECT_FAQ_START",
  GET_PROJECT_FAQ_SUCCESS: "GET_PROJECT_FAQ_SUCCESS",
  GET_PROJECT_FAQ_ERROR: "GET_PROJECT_FAQ_ERROR",
  SET_SELECTED_FAQ: "SET_SELECTED_FAQ",
  SET_PROJECT_FAQ_BATCH_NUMBER: "SET_PROJECT_FAQ_BATCH_NUMBER",
  UPDATE_PROJECT_FAQ_START: "UPDATE_PROJECT_FAQ_START",
  UPDATE_PROJECT_FAQ_SUCCESS: "UPDATE_PROJECT_FAQ_SUCCESS",
  UPDATE_PROJECT_FAQ_ERROR: "UPDATE_PROJECT_FAQ_ERROR",
  DELETE_FAQ_START: "DELETE_FAQ_START",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_ERROR: "DELETE_FAQ_ERROR",
};

export const ProjectManagementActions = {
  getAllProjectDetailsStart: (data) => ({
    type: ProjectManagementMap.GET_ALL_PROJECT_DETAIL_START,
    payload: data,
  }),
  getProjectDetailsSuccess: (data) => ({
    type: ProjectManagementMap.GET_ALL_PROJECT_DETAIL_SUCCESS,
    payload: data,
  }),
  getProjectDetailsError: (errors) => ({
    type: ProjectManagementMap.GET_ALL_PROJECT_DETAIL_ERROR,
    payload: { errors },
  }),
  updateProjectDetailsStart: (data) => ({
    type: ProjectManagementMap.UPDATE_PROJECT_DETAIL_START,
    payload: data,
  }),
  updateProjectDetailsSuccess: (data) => ({
    type: ProjectManagementMap.UPDATE_PROJECT_DETAIL_SUCCESS,
    payload: data,
  }),
  updateProjectDetailsError: (errors) => ({
    type: ProjectManagementMap.UPDATE_PROJECT_DETAIL_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: ProjectManagementMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshProjectList: () => ({
    type: ProjectManagementMap.REFRESH_PROJECT_DETAILS_LIST,
  }),
  searchTextChange: (data) => ({
    type: ProjectManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  searchStatusChange: (data) => ({
    type: ProjectManagementMap.SEARCH_STATUS_CHANGE,
    payload: data,
  }),
  setSort: (data) => ({
    type: ProjectManagementMap.SET_SORT_CHANGE,
    payload: data,
  }),
  setProjectDetailBatchNumber: (data) => ({
    type: ProjectManagementMap.SET_PROJECT_DETAIL_BATCH_NUMBER,
    payload: data,
  }),

  setFaqBatchNumber: (data) => ({
    type: ProjectManagementMap.SET_PROJECT_FAQ_BATCH_NUMBER,
    payload: data,
  }),

  getProjectStart: (data) => ({
    type: ProjectManagementMap.GET_PROJECT_START,
    payload: data,
  }),
  getProjectSuccess: (data) => ({
    type: ProjectManagementMap.GET_PROJECT_SUCCESS,
    payload: data,
  }),
  getProjectError: (data) => ({
    type: ProjectManagementMap.GET_PROJECT_ERROR,
    payload: data,
  }),

  getProjectFaqStart: (data) => ({
    type: ProjectManagementMap.GET_PROJECT_FAQ_START,
    payload: data,
  }),
  getProjectFaqSuccess: (data) => ({
    type: ProjectManagementMap.GET_PROJECT_FAQ_SUCCESS,
    payload: data,
  }),
  getProjectFaqError: (data) => ({
    type: ProjectManagementMap.GET_PROJECT_FAQ_ERROR,
    payload: data,
  }),

  updateProjectFaqStart: (data) => ({
    type: ProjectManagementMap.UPDATE_PROJECT_FAQ_START,
    payload: data,
  }),
  updateProjectFaqSuccess: (data) => ({
    type: ProjectManagementMap.UPDATE_PROJECT_FAQ_SUCCESS,
    payload: data,
  }),
  updateProjectFaqError: (errors) => ({
    type: ProjectManagementMap.UPDATE_PROJECT_FAQ_ERROR,
    payload: { errors },
  }),
  setSelectedFaq: (data) => ({
    type: ProjectManagementMap.SET_SELECTED_FAQ,
    payload: data,
  }),
  deleteProjectFaqStart: (data) => ({
    type: ProjectManagementMap.DELETE_FAQ_START,
    payload: data,
  }),
  deleteProjectFaqSuccess: (data) => ({
    type: ProjectManagementMap.DELETE_FAQ_SUCCESS,
    payload: data,
  }),
  deleteProjectFaqError: (data) => ({
    type: ProjectManagementMap.DELETE_FAQ_ERROR,
    payload: data,
  }),
};
